<template>
  <div>
    <v-tabs class="mt-2" v-model="activeTab" background-color="primary" dark>
      <v-tab @click="changeComponent('EditSubscription')">إعادة توليد الإشتراك</v-tab>
      <v-tab @click="changeComponent('EditCustomer')">    بيانات العميل</v-tab>
      <v-tab @click="changeComponent('CustomerAddress')">      عناوين العميل</v-tab>
      <v-tab @click="changeComponent('DeliveryDetails')">  التوصيل الخاص بالإشتراك</v-tab>
    </v-tabs>

    <component :is="componentName" />
  </div>
</template>

<script>
import EditSubscription from './edit.vue'
import CustomerAddress from '/src/views/pages/customers/address/create.vue'
import EditCustomer from '/src/views/pages/customers/edit.vue'
import DeliveryDetails from './deliver_details.vue'

export default {
  components: {
    EditSubscription,
    CustomerAddress,
    DeliveryDetails,
    EditCustomer
  },
  data() {
    return {
      activeTab: null,
      componentName: 'EditSubscription'
    };
  },
  methods: {
    changeComponent(component) {
      this.componentName = component;
    }
  },
  created() {
    this.activeTab = 'EditSubscription';
  }
};
</script>

<style>
/* Add your custom styles here */
</style>
